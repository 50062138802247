import Layout from "../../layout/Layout";
import AiContentGeneratorCta from "../../components/aiContentGenerator/AiContentGeneratorCta";
import AiContentGeneratorFeedback from "../../components/aiContentGenerator/AiContentGeneratorFeedback";
import FooterOne from "../../layout/Footer/FooterOne";
import AiContentGeneratorHero from "../../components/aiContentGenerator/AiContentGeneratorHero";
import AiContentGeneratorInfocard from "../../components/aiContentGenerator/AiContentGeneratorInfocard";
import AiContentGeneratorIntegration from "../../components/aiContentGenerator/AiContentGeneratorIntegration";
import AiContentGeneratorLogo from "../../components/aiContentGenerator/AiContentGeneratorLogo";
import AiContentGeneratorPricing from "../../components/aiContentGenerator/AiContentGeneratorPricing";
import AiContentGeneratorTab from "../../components/aiContentGenerator/AiContentGeneratorTab";
import AiContentGeneratorWork from "../../components/aiContentGenerator/AiContentGeneratorWork";
import Navbar from "../../layout/Header/Navbar";
import PageMeta from "../../components/common/PageMeta"
const GraphicDesgining = () => {
  return (
    <Layout>
      <PageMeta title='Graphic Desgining - Senertech' /> 
      <Navbar />
      <AiContentGeneratorHero />
      {/* <AiContentGeneratorLogo /> */}
      <AiContentGeneratorTab />
      <AiContentGeneratorInfocard />
      <AiContentGeneratorWork />
      {/* <AiContentGeneratorIntegration /> */}
      {/* <AiContentGeneratorFeedback /> */}
      {/* <AiContentGeneratorPricing /> */}
      {/* <AiContentGeneratorCta /> */}
      <FooterOne 
      style={{
        background: "url('/page-header-bg.svg')no-repeat bottom right",
      }}/>
    </Layout>
  );
};

export default GraphicDesgining;
