import React from "react";
import PageHeader from "../components/common/PageHeader";
import PageMeta from "../components/common/PageMeta";
import ContactFormThree from "../components/contact/ContactFormThree";
import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import {GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


const Contact = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfWerEqAAAAAC_h_iTDZKFAfjkRog8fCigC_VLB" >
    <Layout>
      <PageMeta title="Contact us - Senertech" />
      <Navbar classOption="navbar-light" />
      <PageHeader
        title="Get in Touch Today!"
        desc="At Senertech, we understand that every business has distinct challenges and goals. That’s why we take the time to listen and collaborate with you to design software that aligns perfectly with your vision. Our commitment to quality and innovation ensures that you receive a solution that not only meets your requirements but also drives measurable results."
      />
      <ContactFormThree/>
      {/* <ContactBox /> */}
      {/* <ContactFormTwo /> */}
      <FooterOne
        // style={{
        //   background:
        //     "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        // }}
      />
    </Layout>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
