import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from "react-bootstrap";


const FooterOne = ({ footerLight, style, footerGradient }) => {
  const cardStyle = 
  footerLight ? 
  {
    height: "330.5px" ,
    borderRadius:"20px",
    backgroundColor: "rgb(245, 245, 220)", 
    color: "#000"
  } 
  :  
  { 
    height: "330.5px" ,
    borderRadius:"20px",
    backgroundColor: "rgb(55, 65, 81)", 
    color: "#000"
   }

  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${footerGradient ? 'bg-gradient' : ''
            }  text-white ptb-40`}
          style={style}
        >
          <div className='container'>

            <div className='row justify-content-between'>
              <div className='col-md-6 col-lg-3 mt-4 mt-md-0 mt-lg-0'>
                <Card   
                style={cardStyle}
                   >
                  <Card.Header className="bg-transparent">
                    <p className='fw-bold  ms-2 pt-2 text-capitalize'>Company</p>
                  </Card.Header>
                  <Card.Body>
                    <ul className='list-unstyled footer-nav-list mb-lg-0 ms-2'>
                      <li>
                        <Link
                         to='/about-us'
                          className='text-decoration-none fw-light '
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link

                          className='text-decoration-none fw-light'
                          style={{
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: 0.7, // Make it lighter
                            color: "#adb5bd", // Light gray text color
                          }}
                        >
                          Career
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/contact-us'
                          className='text-decoration-none fw-light '
                        >
                          Contact
                        </Link>
                      </li>

                      {/* <li>
                          <Link    
                            className='text-decoration-none fw-light text-black'
                          >
                            Knowledgebase
                          </Link>
                        </li> */}

                    </ul>
                  </Card.Body>
                </Card>
              </div>

              <div className='col-md-6 col-lg-3 mt-4 mt-md-0 mt-lg-0'>
                <Card style ={cardStyle}>
                  <Card.Header className="bg-transparent">
                    <p className='fw-bold  ms-2 pt-2 text-capitalize'>Services</p>
                  </Card.Header>
                  <Card.Body>
                    <ul className='list-unstyled footer-nav-list mb-lg-0 ms-2'>
                      <li>
                        <Link
                          to='/it-consultation'
                          className='text-decoration-none fw-light '>
                          IT Consultation
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/web-devlopment'
                          className='text-decoration-none fw-light '>
                          Web & App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/digital-marketing'
                          className='text-decoration-none fw-light '
                        >
                          Digital Marketing
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/graphic-designing'
                          className='text-decoration-none fw-light '
                        >
                          Graphic Designing
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/social-media-managment'
                          className='text-decoration-none fw-light '
                        >
                          Social Media Management
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/video-marketing-services'
                          className='text-decoration-none fw-light '
                        >
                          Video Making
                        </Link>
                      </li>
                    </ul>
                  </Card.Body>
                  {/* </div> */}
                </Card>
              </div>

              <div className='col-md-6 col-lg-3 mt-4  mt-md-0 mt-lg-0'>
                <Card style={cardStyle}>
                  <Card.Header className="bg-transparent">
                    <p className="text-uppercase fw-bold  ms-2 pt-2 text-capitalize">Automotive Products</p>
                  </Card.Header>
                  <Card.Body>
                    <ul className='list-unstyled footer-nav-list mb-lg-0 ms-2'>
                      <li>
                        <Link
                          to='/wms'
                          className='text-decoration-none fw-light'
                          onClick={(e) => {
                            e.preventDefault(); 
                            window.open('/wms', '_blank', 'noopener,noreferrer');
                          }}>
                          WMS
                        </Link>
                      </li>
                      <li>
                        <Link
                          title='Coming Soon ..'
                          style={{
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: 0.7, // Make it lighter
                            color: "#adb5bd", // Light gray text color
                          }}

                          className='text-decoration-none fw-light  '>
                          ABMS
                          <span className="ail-highlighted-text"> (Coming Soon)</span>
                        </Link>
                      </li>

                    </ul>
                  </Card.Body>
                </Card>
              </div>

              <div className='col-md-6 col-lg-3 mt-4 mt-md-0 mt-lg-0'>
                <Card style={cardStyle}>
                  <Card.Header className="bg-transparent">
                    <p className='fw-bold  ms-2 pt-2 text-capitalize'>Help & Support</p>
                  </Card.Header>
                  <Card.Body>
                    <ul className='list-unstyled footer-nav-list mb-lg-0 ms-2'>
                      
                      <li>
                        <Link
                        style={{
                          pointerEvents: "none",
                          cursor: "default",
                          opacity: 0.7, // Make it lighter
                          color: "#adb5bd", // Light gray text color
                        }}
                          className='text-decoration-none fw-light'
                        >
                          Knowledgebase
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='text-decoration-none fw-light '
                          style={{
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: 0.7, // Make it lighter
                            color: "#adb5bd", // Light gray text color
                          }}
                         >
                          Privacy Policy
                        </Link>
                      </li>

                      <li>
                        <Link
                          className='text-decoration-none fw-light'
                          style={{
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: 0.7, // Make it lighter
                            color: "#adb5bd", // Light gray text color
                          }}
                        >
                          Terms of Use
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='text-decoration-none fw-light '
                          style={{
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: 0.7, // Make it lighter
                            color: "#adb5bd", // Light gray text color
                          }}
                        >
                          End User License Agreement
                        </Link>
                      </li>


                    </ul>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${footerLight ? 'footer-light' : 'bg-dark'
            } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    Copyright © Senertech Software Private Limited. All Rights Reserved
                    {/* <Link
                      to='/https://themetags.com/'
                      className='text-decoration-none ms-2'
                    >
                      ThemeTags
                    </Link> */}
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://www.facebook.com/senertech' target="_blank">
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.instagram.com/sener.tech' target="_blank">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/company/sener-tech' target="_blank">
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://x.com/sener_tech' target="_blank">
                        <i className='fa fa-twitter'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.youtube.com/@senertech' target="_blank">
                        <i className='fab fa-youtube'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
