import React from 'react';
import CreativeAgencyTwoHero from '../components/creativeAgencyTwo/CreativeAgencyTwoHero';
import CreativeAgencyTwoInfocard from '../components/creativeAgencyTwo/CreativeAgencyTwoInfocard';
import PageMeta from '../components/common/PageMeta';

import FeatureImgThree from '../components/features/FeatureImgThree';
import CrmFeedbackSection from '../components/crmHome/CrmFeedbackSection';
import CreativeAgencyTwoMissioneam from '../components/creativeAgencyTwo/CreativeAgencyTwoMission';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const About = () => {
  return (
    <Layout>
      <PageMeta title='About us - Senertech' />      
      <Navbar />
      <CreativeAgencyTwoHero />
      <CreativeAgencyTwoInfocard />
      <CreativeAgencyTwoMissioneam />
      <FeatureImgThree />

      <CrmFeedbackSection />
      {/* <CtaTwo /> */}
      <FooterOne />
    </Layout>
  );
};

export default About;
