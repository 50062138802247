
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import PageMeta from "../../components/common/PageMeta"
import MarketplaceAgencyCard from "../../components/marketplaceAgency/MarketplaceAgencyCard";
import MarketplaceAgencyContact from "../../components/marketplaceAgency/MarketplaceAgencyContact";
import MarketplaceAgencyDarkCard from "../../components/marketplaceAgency/MarketplaceAgencyDarkCard";
import MarketplaceAgencyFaq from "../../components/marketplaceAgency/MarketplaceAgencyFaq";
import MarketplaceAgencyFeedback from "../../components/marketplaceAgency/MarketplaceAgencyFeedback";
import MarketplaceAgencyHero from "../../components/marketplaceAgency/MarketplaceAgencyHero";
import MarketplaceAgencyLogo from "../../components/marketplaceAgency/MarketplaceAgencyLogo";

const DigitalMarketing = () => {
  return (
    <Layout>
       <PageMeta title='Digital Marketing - Senertech' />  
      <Navbar />
      <MarketplaceAgencyHero />
      <MarketplaceAgencyCard />
      {/* <MarketplaceAgencyLogo /> */}
      <MarketplaceAgencyDarkCard />
      <MarketplaceAgencyFeedback />
      {/* <MarketplaceAgencyContact /> */}
      {/* <MarketplaceAgencyFaq /> */}
      <FooterOne
        style={{
          background: "url('/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};


export default DigitalMarketing;
