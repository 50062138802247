import FooterOne from "../layout/Footer/FooterOne";
import SoftwareCompanyAbout from "../components/softwareCompanyHome/SoftwareCompanyAbout";
import AiCompanyProcess from "../components/aiCompany/AiCompanyProcess.js";
import SoftwareCompanyHero from "../components/softwareCompanyHome/SoftwareCompanyHero";
import SoftwareCompanyPortfolio from "../components/softwareCompanyHome/SoftwareCompanyPortfolio.js"
import CrmFeedbackSection from "../components/crmHome/CrmFeedbackSection.js";
import AiCompanyLineSlider from "../components/aiCompany/AiCompanyLineSlider.js"
import AiCompanyPortfolio from "../components/aiCompany/AiCompanyPortfolio";
import FeatureTwo from "../components/features/FeatureTwo"
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import PageMeta from "../components/common/PageMeta.js"


const SoftwareCompany = () => {
  return (
    <Layout>
      <Navbar navDark />
      <PageMeta title='Senertech' />
      <SoftwareCompanyHero />  
      <SoftwareCompanyAbout />
      <FeatureTwo 
      cardDark = {"bg-dark"} />
      <SoftwareCompanyPortfolio/>
      {/* <AiCompanyPortfolio /> */}
      <AiCompanyLineSlider />
      <CrmFeedbackSection />
      <AiCompanyProcess />
      {/* <SoftwareCompanyTeam /> */}
      {/* <SoftwareCompanyPricing /> */}
      {/* <SoftwareCompanyBlog /> */}
      <FooterOne />
    </Layout>
  );
};

export default SoftwareCompany;
