import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Terms from "./terms"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from 'axios';

const InquiryForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [openTerms,setOpenTerms] =useState(false)
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [mobile,setMobile] = useState("")
  const [city,setCity] = useState("")
  const [service,setService] = useState("")
  const [state,setState] = useState("")
  const [otherRequirments,setOtherRequirments] = useState("")

  const states = [
    {value:"state",label:"State"},
    {value:"Andhra Pradesh",label:"Andhra Pradesh"},
    {value:"Arunachal Pradesh",label:"Arunachal Pradesh"},
    {value:"Assam",label:"Assam"},
    {value:"Bihar",label:"Bihar"},
    {value:"Chhattisgarh",label:"Chhattisgarh"},
    {value:"Goa",label:"Goa"},
    {value:"Gujarat",label:"Gujarat"},
    {value:"Haryana",label:"Haryana"},
    {value:"Himachal Pradesh",label:"Himachal Pradesh"},
    {value:"Jharkhand",label:"Jharkhand"},
    {value:"Karnataka",label:"Karnataka"},
    {value:"Kerala",label:"Kerala"},
    {value:"Madhya Pradesh",label:"Madhya Pradesh"},
    {value:"Maharashtra",label:"Maharashtra"},
    {value:"Manipur",label:"Manipur"},
    {value:"Meghalaya",label:"Meghalaya"},
    {value:"Mizoram",label:"Mizoram"},
    {value:"Nagaland",label:"Nagaland"},
    {value:"Odisha",label:"Odisha"},
    {value:"Punjab",label:"Punjab"},
    {value:"Rajasthan",label:"Rajasthan"},
    {value:"Sikkim",label:"Sikkim"},
    {value:"Tamil Nadu",label:"Tamil Nadu"},
    {value:"Telangana",label:"Telangana"},
    {value:"Tripura",label:"Tripura"},
    {value:"Uttar Pradesh",label:"Uttar Pradesh"}, 
    {value:"Uttarakhand",label:"Uttarakhand"}, 
    {value:"West Bengal",label:"West Bengal"}, 
    {value:"Andaman and Nicobar Islands",label:"Andaman and Nicobar Islands"}, 
    {value:"Chandigarh",label:"Chandigarh"}, 
    {value:"Dadra and Nagar Haveli and Daman and Diu",label:"Dadra and Nagar Haveli and Daman and Diu"}, 
    {value:"Lakshadweep",label:"Lakshadweep"}, 
    {value:"Delhi ",label:"Delhi "}, 
    {value:"Puducherry",label:"Puducherry"}, 
    {value:"Jammu and Kashmir",label:"Jammu and Kashmir"},
    {value:"Ladakh",label:"Ladakh"} 
  ]

  const services = [
    {value:"service",label:"service"},
    {value:"IT Consultation",label:"IT Consultation"},
    {value:"Web & App Development",label:"Web & App Development"},
    {value:"Digital Marketing",label:"Digital Marketing"},
    {value:"Graphic Designing",label:"Graphic Designing"},
    {value:"Social Media Management",label:"Social Media Management"},
    {value:"Video Making",label:"Video Making"},
  ]

  const handleSubmit = async(e) => {
    e.preventDefault()
    if(name === "" ){
      return toast("Name Required",{className: "danger"})
    }

    if(city===""  ){
      return toast("City Required",{className: "danger"})
    }

    if(service==="" || service==="service" ){
      return toast("Select Service",{className: "danger"})
    }

    if(state===""||state==="state" ){
      return toast("State Required",{className: "danger"})
    }

    if(email==="" ){
      return toast("Email Required",{className: "danger"})
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!emailRegex.test(email)){
      return toast("Valid email Required",{className: "danger"})
    }

    if(mobile==="" ){
      return toast("Mobile number Required",{className: "danger"})
    }

    const mobileRegex = /^\+?[1-9]\d{1,14}$/;

    if(!mobileRegex.test(mobile)){
      return toast("Valid Phone Number Required",{className: "danger"})
    }

    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha('submit_action');

    const body = {
      captchaToken: token,
      name: name,
      email: email,
      mobile: mobile,
      city: city,
      service: service,
      state: state,
      otherRequirments: otherRequirments
    }

    // Send the token to your backend for verification using Axios
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/v2/senertech/sendMail`, body ,{
      headers: { 'Content-Type': 'application/json' },
    });

    if(response.data.code === 200){
      toast("We have received your query Sucessfully")
    }

  }
  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1 '>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>

          <form action='#' className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Name'
                    aria-label='name'
                    onChange={(e)=>setName(e.target.value)}
                  />
                </div>
              </div>

              <div className='col-sm-12 d-flex'>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    aria-label='Email'
                    onChange = {(e)=>setEmail(e.target.value)}
                  />
                </div>
                {/* <a className='btn btn-primary mb-3 ms-1'>Verify</a> */}
              </div>

              <div className='col-sm-12 d-flex'>
                <div className='input-group mb-3'>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='Mobile'
                    aria-label='Mobile'
                    onChange = {(e)=>setMobile(e.target.value)}
                  />
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <select
                    className='form-control form-select'
                    name='service'
                    id='service'
                    required=''
                    data-msg='Please select your budget.'
                    onChange={(e)=>{
                      setService(e.target.value)
                    }}
                  >
                    {services.map((value,index)=>(
                      <option key = {index} value={value.value}>{value.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <select
                    className='form-control form-select'
                    name='state'
                    id='state'
                    required='yes'
                    data-msg='Please select your state.'
                    onChange={(selectItem)=>{
                      setState(selectItem.target.value)
                    }
                    }
                  >
                    {states.map((value,index)=>(
                      <option key = {index} value={value.value}>{value.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='City'
                    aria-label='city'
                    onChange = {(e)=>setCity(e.target.value)}
                  />
                </div>
              </div>
             
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    placeholder='Tell us about your project, requirement and budget'
                    style={{ height: '70px' }}
                    onChange ={(e)=>{setOtherRequirments(e.target.value)}}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckChecked'
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='flexCheckChecked'
                  >
                    Yes, I'd like to receive occasional marketing emails from
                    us. I have the right to opt out at any time.
                    {/* <Link to='#' onClick={()=>setOpenTerms(true)}> View privacy policy</Link>. */}
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                  onClick={(e)=>handleSubmit(e)}
                >
                  Submit
                </button>
                <ToastContainer />
              </div>
            </div>
          </form>
        </div>
        <Terms
      show = {openTerms}
      onHide ={()=>setOpenTerms(false)}/>
      </div>
    </>
  );
};

export default InquiryForm;
